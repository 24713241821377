import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import picametyst from '../assets/images/picametyst.jpg'

const NotFoundPage = () => (
  <Layout>
    <div id="main">
      <header>
        <span className="image main" style={{backgroundImage: `url(${picametyst})`}}></span>
      </header>
      <div class="main">
        <h1>Stránka nenalezena</h1>
        <p>Můžete se vrátit na hlavní stránku.</p>
        <ul className="actions">
          <li><Link to="/" className="button">Zpět na Masáže Ametyst</Link> </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
