import React from 'react'

const Footer = () => (
    <footer id="footer">
        <section id="contact">
        <header className="major">
                  <h2>Provozní doba</h2>
                  
                </header>
            <h2></h2>
            <p>Pondělí až pátek dle objednání <br /><strong>Telefon 776 654 175</strong><br /> (nebo zašlete SMS zprávu)</p>
            
        </section>
        <section>
        <header className="major">
                  <h2>Kontakt</h2>
                  
                </header>
            <dl className="alt">
                <dt>Sídlo</dt>
                <dd>Provozovna VÚHŽ a.s., 1. patro, dveře 106</dd>
                <dt>Adresa</dt>
                <dd>739 51 Dobrá 240</dd>
                
                <dt>IČO</dt>
                <dd>77434888</dd>
                <dt>Facebook</dt>
                <dd> <a href="https://www.facebook.com/MasazeAmetyst.Dobra/">Masáže Ametyst</a> </dd>
                
            </dl>
            
        </section>
        <p className="copyright">Copyright &copy; Ametyst masáže 2019. Vytvořeno Kristýna Nogolová.</p>
    </footer>
)

export default Footer
